//==================================================================
// Forms Partial
// Styles for various forms and form elements.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Inputs
input {
  border: 1px solid $color_core_off_white;
  @include rem(padding, 10px);
  @include rem(border-radius, $border_radius);
  transition: border $animation_timing $animation_easing;

  // Sizes
  &[type="text"], &[type="email"], &[type="search"] {
    @include rem(width, 300px);
  }

  // Fix search input
  &[type="search"] {
    box-sizing: border-box;
  }

  // Focus
  &:focus {
    border-color: $color_accent_blue;
    outline: none;
  }
}

// Textarea
textarea {
  border: 1px solid $color_core_off_white;
  @include rem(padding, 10px);
  @include rem(min-width, 300px);
  @include rem(border-radius, $border_radius);
  transition: border $animation_timing $animation_easing;

  // Focus
  &:focus {
    border-color: $color_accent_blue;
    outline: none;
  }
}


// Disable browser controls
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-calendar-picker-indicator{
  display: none;
}
input[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}