body.page-map {

  .header-container {
    position: fixed;
    top: 0;
    left: 0;
  }

  .content-container {
    height: 100%;
  }

  .footer-container {
    display: none;
  }

}