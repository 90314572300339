body.error-404 {
  .content-container {
    height: 100%;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
  }
  .band {
    text-align: center;

    h1 {
      line-height: 1;

      strong {
        display: block;
        @include rem(font-size, 128px);
      }
    }
    h1 + p {
      display: inline-block;
      background-color: lighten($color_core_off_white, 5%);
      @include rem(padding, 10px 20px);
      @include rem(border-radius, $border_radius);
    }
  }
}