//==================================================================
// Content Partial
// Styles for content layout.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Content
// =================================================================
.content-container {
  position: relative;

  @include media($laptop) {
    @include rem(padding-top, 82px);

    .alert-box, confirmation-box {
      @include rem(margin-top, -82px);
      @include rem(margin-bottom, 40px);
    }
  }
}

.page-home .content-container {
  padding-top: 0;
}