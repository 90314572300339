.listing-type-selector {
  @include rem(margin-bottom, 60px);
  @include align-items(center);

  .listing-type-title {
    margin-bottom: 30;
  }
  label {
    @extend .visuallyhidden;
  }
  .field, .buttons {
    display: inline-block;
    vertical-align: bottom;
  }
  .field {
    margin: 0;
  }
  button, .button {
    @include rem(font-size, 14px);
  }
}