//==================================================================
// Global Partial
// Styles for global layout.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.wrapper {
  @include rem(padding, 0 20px);
  transition: padding $animation_timing $animation_easing;

  @include media($phablet) {
    @include rem(padding, 0 40px);
  }
  @include media($tablet) {
    @include rem(padding, 0 60px);
  }
  @include media($laptop) {
    @include rem(padding, 0 80px);
  }
  @include media($desktop) {
    padding: 0;
    margin: 0 auto;
    @include rem(max-width, 1140px);
  }
}