.card {
  display: inline-block;
  width: 100%;
  background-color: $color_core_off_white;
  text-align: center;
  @include rem(padding, 60px 40px);
  @include rem(border-radius, $border_radius);
  transition: background $animation_timing $animation_easing;

  @include media($tablet) {
    width: auto;
  }

  // a {
  //   display: block;
  //   text-decoration: none;
  //   color: #fff;
  //   @include rem(padding, 60px 40px);
  // }
  img {
    @include rem(width, 128px);
    @include rem(height, 128px);
    @include rem(margin, 0 0 32px);

  }
  h2 {
    margin: 0;
    border-bottom: 1px solid rgba(#fff, .25);
    @include rem(padding, 0 0 16px);
    @include rem(font-size, 28px);
  }
  p {
    margin: 0;
    @include rem(padding-top, 16px);
  }

  // Price card
  &.price {
    background: rgb(24,39,47) url('../img/bg_header.jpg') no-repeat 50% 50% / cover;
    color: #fff;
    @include rem(padding, 60px 80px);

    h2 {
      line-height: 1;
      @include rem(padding-bottom, 60px);
      @include rem(margin-bottom, 20px);
      @include rem(font-size, 48px);
    }
    h2 span {
      display: inline-block;
      border-bottom: 1px solid #fff;
      vertical-align: top;
      @include rem(margin, 6px 0 0 8px);
      @include rem(font-size, 21px);
    }
    h2 small {
      display: block;
      font-weight: 400;
      @include rem(margin-top, 10px);
      @include rem(font-size, 21px);
    }
    ul {
      padding: 0;
      list-style: none;
      @include rem(margin, 0 0 20px);
      
      li {
        @include rem(margin-top, 10px);
      }
      li:first-child {
        margin: 0;
      }

    }
    .button + p {
      color: rgba(#fff, .5);
    }
  }

}

// Dashboard cards
.dashboard-cards {

  .wrapper {
    @include display(flex);
  
    .card {
      width: percentage(1/3);
      padding: 0;
      @include rem(margin-left, 10px);
    }
    .card a {
      display: block;
      text-decoration: none;
      color: #fff;
      @include rem(padding, 60px 40px);
    }
    .card a > img {
      box-shadow: 0 0 0 0 rgba(#000, 0);
      border-radius: 50%;
    }
    .card:first-child {
      margin: 0;
      background-color: $color_accent_dark_green;
    
      &:hover {
        background-color: lighten($color_accent_dark_green, 5%);
      }
    }
    .card:nth-child(2) {
      background-color: $color_accent_blue;

      &:hover {
        background-color: lighten($color_accent_blue, 5%);
      }
    }
    .card:nth-child(3) {
      background-color: $color_accent_lime_green;

      &:hover {
        background-color: lighten($color_accent_lime_green, 5%);
      }
    }

    // Hover states
    .card:hover a > img {
      animation: pulse $animation_timing * 5 $animation_easing infinite;
    }
  }

}

@keyframes pulse {
  0%   { box-shadow: 0 0 0 0    rgba(#fff, .25); }
  100% { box-shadow: 0 0 0 20px rgba(#fff,   0); }
}