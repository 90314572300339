.pagination {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  font-size: 0;
  line-height: 0;
  @include rem(margin-top, 32px);

  li {
    display: inline-block;
    line-height: 1;
    @include rem(margin-left, 4px);
    @include rem(font-size, 16px);

    span, a {
      display: block;
      text-decoration: none;
      font-weight: 600;
      color: $color_core_black;
      @include rem(padding, 10px 14px);
      @include rem(border-radius, $border_radius);
      transition: background $animation_timing $animation_easing;
    }

    // Hover
    a:hover {
      background-color: lighten($color_core_off_white, 5%);
    }

    // Disabled
    &.disabled {}

    // Active
    &.active {
      span {
        background-color: $color_core_black;
        color: #fff;
      }
    }
  }
}