//==================================================================
// Header Partial
// Styles for header layout.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Header
// =================================================================
.header-container {
  background: rgb(24,39,47) url('../img/bg_header.jpg') no-repeat 50% 50% / cover;
  box-shadow: 0 1px 2px rgba(0,0,0,.25);
  color: #fff;
  z-index: 11;
  width: 100%;

  @include rem(padding, 10px 0);
  
  @include media($tablet) {
    @include rem(padding, 20px 0);
  }

  // Logo
  .logo {
    float: left;
    font-weight: 600;
    text-align: center;
    margin: 0;
    @include rem(font-size, 16px);
    @include rem(letter-spacing, -1px);
    @include rem(line-height, 32px);
    
    @include media($tablet) {
      margin: 0;
      @include rem(font-size, 24px);
    }

    a {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      vertical-align: middle;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      @include rem(width, 32px);
      @include rem(height, 32px);
      @include rem(margin-right, 10px);
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  // User
  .user {
    @include media($tablet) {
      float: right;
    }
  }
}