// Override font family
.ui-widget,
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: $base_font;
  @include rem(font-size, 16px);
}

// UI Tabs
.ui-tabs {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;

  .ui-tabs-nav {
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    overflow: hidden;
    @include rem(margin-bottom, 64px);
    @include rem(border-radius, $border_radius);
  
    li {
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      overflow: hidden;
    }
    li a {
      background: lighten($color_core_off_white, 5%);
      font-family: $base_font;
      font-weight: 600;
      @include rem(font-size, 14px);
    }

    // Last
    li:last-child {
      @include rem(border-top-right-radius, $border_radius);
      @include rem(border-bottom-right-radius, $border_radius);
    }

    // Active
    .ui-state-active a,
    .ui-state-active a:link,
    .ui-state-active a:visited {
      background-color: $color_accent_dark_green;
      color: #fff;
    }
  }

  .ui-tabs-panel {
    padding: 0;
  }
}


// UI Datepicker
.ui-datepicker {
  padding: 0;

  .ui-widget-header {
    background: $color_accent_dark_green;
    border: 0;
    border-radius: 0;
    color: #fff;
  }
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    background: #fff;
    border: 0;
    color: $color_core_black;
    text-align: center;
  }
  .ui-state-hover,
  .ui-widget-content .ui-state-hover,
  .ui-widget-header .ui-state-hover {
    background: $color_core_off_white;
    @include rem(border-radius, $border_radius);
  }
  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active {
    background: $color_accent_blue;
    color: #fff;
    @include rem(border-radius, $border_radius);
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    background: none;
    text-align: center;
    font-weight: 700;
    color: lighten($color_accent_dark_green, 40%);
    cursor: pointer;
  
    span {
      display: none;
    }

    &:hover {
      top: 2px !important;
      background: none !important;
      border: 0 !important;
      color: #fff;
    }
  }
  .ui-datepicker-prev {
    &:before {
      content: "\02190";
    }
    &:hover {
      left: 2px !important;
    }
  }
  .ui-datepicker-next {
    &:before {
      content: "\02192";
    }
    &:hover {
      right: 2px !important;
    }
  }

  // table
  table {
    margin: 0;
  }
  table tbody {
    @include rem(padding, 20px 0);
  }
  table thead th {
    background: $color_accent_dark_green;
  }
  table tbody tr:hover th,
  table tbody tr:hover td {
    background: none;
  }
}