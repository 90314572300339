body.app-login,
body.app-register,
body.app-forgot,
body.app-thankyou {
  position: relative;
  background: url('../img/p_housing-sunset.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/p_housing-sunset.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/p_housing-sunset.jpg', sizingMethod='scale')";

  .header-container {
    @include media($laptop) {
      @include rem(height, 82px);
    }
  }
  nav {
    display: none;
  }

  .alert-box , .confirmation-box{
    @include rem(margin-top, 100px);
  }

  .band, .band > .wrapper {
    height: 100%;
    padding: 0;
  }
  .content-container {
    height: 90%;
    height: calc(100% - 83px);
    position: relative;
    @include display(flex);
    @include flex-direction(column);
    @include align-content(center);
    @include justify-content(center);
  }

  .band > .wrapper {
    @include display(flex);
    @include flex-direction(column);
    @include align-content(center);
    @include justify-content(center);
    @include rem(padding-bottom, 45px);
  }

  .footer-container {
    border: 0;
    color: #fff;
    background-color: rgba($color_accent_dark_green, .9);
    @include rem(margin-top, -45px);
  }

}
body.app-thankyou {
  .alert-box.success, .confirmation-box.success {
    display: none;
  }
}