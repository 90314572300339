//==================================================================
// Alerts Partial
// Styles that help communicate success, warnings, failures, etc.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Chrome Frame - for unsupported versions of IE
// =================================================================
.chromeframe {
}

// Alerts
// =================================================================
.alert-box, .confirmation-box {
  background-color: $color_accent_dark_green;
  color: #fff;
  z-index: 10;
  transition: margin $animation_timing $animation_easing;

  .wrapper {
    position: relative;
    @include display(flex);
    @include align-items(stretch);
  }
  .category {
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include rem(padding, 20px 40px);
  }
  .message {
    background-color: $color_accent_dark_green;
    @include flex-grow(2);
    @include rem(padding, 20px);
  }
  ul {

  }

  // Variations
  &.error {  
    .category {
      background-color: $color_accent_red;    
    }
  }
  &.success {
    .category {
      background-color: $color_accent_lime_green;    
    }
  }
  &.inline {
    overflow: hidden;
    @include rem(margin-top, 16px);
    @include rem(border-radius, $border_radius);
  
    .message {
      @include rem(padding, 10px 20px);
    }
    .message span {
      display: inline-block;
      @include rem(margin-right, 16px);
    }
  }
  &.global {
    margin-bottom: 10px;
  }

}