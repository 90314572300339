.filter-bar {
  @include rem(margin-bottom, 30px);
  @include display(flex);
  @include align-items(center);
  @include justify-content(space-between);

  .filter-bar-title {
    margin: 0;
  }
  label {
    @extend .visuallyhidden;
  }
  .field, .buttons {
    display: inline-block;
    vertical-align: bottom;
  }
  .field {
    margin: 0;
  }
  button, .button {
    @include rem(font-size, 14px);
  }
}