// Fieldset
fieldset {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 1px solid $color_core_off_white;
  vertical-align: top;
  @include rem(padding, 40px);
  @include rem(margin-right, 40px);
  @include rem(border-radius, $border_radius);
}
legend {
  display: block;
  margin: 0;
  color: $color_accent_blue;
  box-shadow: 0 0 0 10px #fff;
}

// Default field
.field {
  position: relative;
  @include rem(margin, 0 0 16px);
}

// Required fields
.req-fields {
  color: darken($color_core_off_white, 25%);
  @include rem(font-size, 14px);
}

// Fieldgroup
.fieldgroup {

  // Address fieldgroup
  &.address {
    
    .field.street input:last-child {
      display: block;
      @include rem(margin-top, 10px);
    }
    .field.zip input {
      @include rem(width, 150px);
    }

  }

}

.url-field {
  @include rem(font-size, 12px);
  @include rem(line-height, 22px);
}