//==================================================================
// Functions and Mixins
// Organizes STAMP and custom Sass mixins for the project.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================/


// Convert Px to Rem
// =================================================================/

	/*
    Variable: fontsize-root
    'px' font size on root (html) element (with units removed).  Browser default is 16px.  Used to determine sizes for rem function.

    -@ based on http://css-tricks.com/snippets/css/less-mixin-for-rem-font-sizing/
    */
    $fontsize-root: 16 !default;

    /*
    Mixin: rem (https://github.com/tobymackenzie/html-boilerplate/blob/master/web/_/styles/src/mixins/_units.scss)
    Output a property with pixel values for non 'rem' supporting browsers followed by the 'rem' equivalent for rem support browsers.  Can't currently accept values with comma separation, like multiple backgrounds or the like.

    -@ based on http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
    */

    @mixin rem($property, $values) {
        $px: ();
        $rem: ();

        @each $value in $values{
            //--get unit or 'nan' if the value isn't a number
            // $unit: if(type-of($value) == 'number', unit($value), 'nan');
            $unit: 'nan';
            @if type-of($value) == 'number'{
                @if unitless($value){
                    $unit: 'unitless';
                }@else{
                    $unit: unit($value);
                }
            }

            //--unitless numbers are added as if they are in pix
            @if $unit == 'unitless' and $value != 0{
                $px : append($px, $value + px);
                $rem: append($rem, ($value / $fontsize-root + rem));
            //--px or rem get added to their list and then converted to the other unit
            }@else if $unit == 'px' or $unit == 'rem'{
                $unitlessVal: stripUnit($value);
                @if $unit == 'px'{
                    $px : append($px, $value);
                    $rem: append($rem, ($unitlessVal / $fontsize-root + rem));
                }@else if $unit == 'rem'{
                    $px : append($px,($unitlessVal * $fontsize-root + px));
                    $rem: append($rem, $value);
                }
            //--all other units get appended directly
            }@else{
                $px : append($px , $value);
                $rem: append($rem, $value);
            }
        }

        @if $px == $rem {
            #{$property}: $px;
        } @else {
            #{$property}: $px;
            #{$property}: $rem;
        }
    }

    /*
    Mixin: stripUnit
    Strip the unit from a value that has a unit

    -@ based on http://css-tricks.com/snippets/css/less-mixin-for-rem-font-sizing/
    */
    @function stripUnit($num) {
        @return $num / ($num * 0 + 1);
    }

	// Font-face Mixin
	//--------------------------------------------------------------*/
	@mixin declare-font-face($font-family, $font-filename, $font-weight : normal, $font-style :normal, $font-stretch : normal) {
		@font-face {
			font-family: '#{$font-family}';
				src: url('../fonts/#{$font-filename}.eot');
				src: url('../fonts/#{$font-filename}.eot?#iefix') format('embedded-opentype'),
				url('../fonts/#{$font-filename}.woff') format('woff'),
				url('../fonts/#{$font-filename}.ttf') format('truetype'),
				url('../fonts/#{$font-filename}.svg##{$font-family}') format('svg');
			font-weight: $font-weight;
			font-style: $font-style;
			font-stretch: $font-stretch;
		}
	}



// CSS3 Flexible Box Model and property defaults

// Custom shorthand notation for flexbox
@mixin box($orient: inline-axis, $pack: start, $align: stretch) {
  @include display-box;
  @include box-orient($orient);
  @include box-pack($pack);
  @include box-align($align);
}

@mixin display-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox; // IE 10
}

@mixin box-orient($orient: inline-axis) {
// horizontal|vertical|inline-axis|block-axis|inherit
  @include prefixer(box-orient, $orient, webkit moz spec);
}

@mixin box-pack($pack: start) {
// start|end|center|justify
  @include prefixer(box-pack, $pack, webkit moz spec);
  -ms-flex-pack: $pack; // IE 10
}

@mixin box-align($align: stretch) {
// start|end|center|baseline|stretch
  @include prefixer(box-align, $align, webkit moz spec);
  -ms-flex-align: $align; // IE 10
}

@mixin box-direction($direction: normal) {
// normal|reverse|inherit
  @include prefixer(box-direction, $direction, webkit moz spec);
  -ms-flex-direction: $direction; // IE 10
}

@mixin box-lines($lines: single) {
// single|multiple
  @include prefixer(box-lines, $lines, webkit moz spec);
}

@mixin box-ordinal-group($int: 1) {
  @include prefixer(box-ordinal-group, $int, webkit moz spec);
  -ms-flex-order: $int; // IE 10
}

@mixin box-flex($value: 0.0) {
  @include prefixer(box-flex, $value, webkit moz spec);
  -ms-flex: $value; // IE 10
}

@mixin box-flex-group($int: 1) {
  @include prefixer(box-flex-group, $int, webkit moz spec);
}

// CSS3 Flexible Box Model and property defaults
// Unified attributes for 2009, 2011, and 2012 flavours.

// 2009 - display (box | inline-box)
// 2011 - display (flexbox | inline-flexbox)
// 2012 - display (flex | inline-flex)
@mixin display($value) {
//  flex | inline-flex
    @if $value == "flex" {
        // 2012
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox; // 2011 (IE 10)
        display: flex;
    }

    @elseif $value == "inline-flex" {
        display: -webkit-inline-box;
        display: -moz-inline-box;
        display: inline-box;

        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    @else {
        display: $value;
    }
}

// 2009 - box-flex (integer)
// 2011 - flex (decimal | width decimal)
// 2012 - flex (integer integer width)
@mixin flex($value) {

    // Grab flex-grow for older browsers.
    $flex-grow: nth($value, 1);

    // 2009
    @include prefixer(box-flex, $flex-grow, webkit moz spec);

    // 2011 (IE 10), 2012
    @include prefixer(flex, $value, webkit moz ms spec);
}

// 2009 - box-orient ( horizontal | vertical | inline-axis | block-axis)
//      - box-direction (normal | reverse)      
// 2011 - flex-direction (row | row-reverse | column | column-reverse)
// 2012 - flex-direction (row | row-reverse | column | column-reverse)
@mixin flex-direction($value: row) {

    // Alt values.
    $value-2009: $value;
    $value-2011: $value;
    $direction: "normal";

    @if $value == row {
        $value-2009: horizontal;
    }

    @elseif $value == "row-reverse" {
        $value-2009: horizontal;
        $direction: reverse;
    }

    @elseif $value == column {
        $value-2009: vertical;
    }

    @elseif $value == "column-reverse" {
        $value-2009: vertical;
        $direction: reverse;
    }

    // 2009
    @include prefixer(box-orient, $value-2009, webkit moz spec);
    @if $direction == "reverse" {
        @include prefixer(box-direction, $direction, webkit moz spec);
    }

    // 2012
    @include prefixer(flex-direction, $value, webkit moz spec);

    // 2011 (IE 10)
    -ms-flex-direction: $value;
}

// 2009 - box-lines (single | multiple)
// 2011 - flex-wrap (nowrap | wrap | wrap-reverse)
// 2012 - flex-wrap (nowrap | wrap | wrap-reverse)
@mixin flex-wrap($value: nowrap) {

    // Alt values.
    $alt-value: $value;
    @if $value == nowrap {
        $alt-value: single;
    }

    @elseif $value == wrap {
        $alt-value: multiple;
    }

    @elseif $value == "wrap-reverse" {
        $alt-value: multiple;
    }

    @include prefixer(box-lines, $alt-value, webkit moz spec);
    @include prefixer(flex-wrap, $value, webkit moz ms spec);
}

// 2009 - TODO: parse values into flex-direction/flex-wrap
// 2011 - TODO: parse values into flex-direction/flex-wrap
// 2012 - flex-flow (flex-direction || flex-wrap)
@mixin flex-flow($value) {
    @include prefixer(flex-flow, $value, webkit moz spec);
}

// 2009 - box-ordinal-group (integer)
// 2011 - flex-order (integer)
// 2012 - order (integer)
@mixin order($int: 0) {
    // 2009
    @include prefixer(box-ordinal-group, $int, webkit moz spec);

    // 2012
    @include prefixer(order, $int, webkit moz spec);

    // 2011 (IE 10)
    -ms-flex-order: $int;
}

// 2012 - flex-grow (number)
@mixin flex-grow($number: 0) {
    @include prefixer(flex-grow, $number, webkit moz spec);
    -ms-flex-positive: $number;
}

// 2012 - flex-shrink (number)
@mixin flex-shrink($number: 1) {
    @include prefixer(flex-shrink, $number, webkit moz spec);
    -ms-flex-negative: $number;
}

// 2012 - flex-basis (number)
@mixin flex-basis($width: auto) {
    @include prefixer(flex-basis, $width, webkit moz spec);
    -ms-flex-preferred-size: $width;
}

// 2009 - box-pack (start | end | center | justify)
// 2011 - flex-pack (start | end | center | justify)
// 2012 - justify-content (flex-start | flex-end | center | space-between | space-around)
@mixin justify-content ($value: flex-start) {

    // Alt values.
    $alt-value: $value;
    @if $value == "flex-start" {
        $alt-value: start;
    }

    @elseif $value == "flex-end" {
        $alt-value: end;
    }

    @elseif $value == "space-between" {
        $alt-value: justify;
    }

    @elseif $value == "space-around" {
        $alt-value: center;
    }

    // 2009
    @include prefixer(box-pack, $alt-value, webkit moz spec);

    // 2012
    @include prefixer(justify-content, $value, webkit moz ms o spec);

    // 2011 (IE 10)
    -ms-flex-pack: $alt-value;
}

// 2009 - box-align (start | end | center | baseline | stretch)
// 2011 - flex-align (start | end | center | baseline | stretch)
// 2012 - align-items (flex-start | flex-end | center | baseline | stretch)
@mixin align-items($value: stretch) {

    $alt-value: $value;

    @if $value == "flex-start" {
        $alt-value: start;
    }    

    @elseif $value == "flex-end" {
        $alt-value: end;
    }

    // 2009
    @include prefixer(box-align, $alt-value, webkit moz spec);

    // 2012
    @include prefixer(align-items, $value, webkit moz ms o spec);
    
    // 2011 (IE 10)
    -ms-flex-align: $alt-value;    
}

// 2011 - flex-item-align (auto | start | end | center | baseline | stretch)
// 2012 - align-self (auto | flex-start | flex-end | center | baseline | stretch)
@mixin align-self($value: auto) {

    $value-2011: $value;
    @if $value == "flex-start" {
        $value-2011: start;
    }    

    @elseif $value == "flex-end" {
        $value-2011: end;
    }

    // 2012
    @include prefixer(align-self, $value, webkit moz spec);

    // 2011 (IE 10)
    -ms-flex-item-align: $value-2011;
}

// 2011 - flex-line-pack (start | end | center | justify | distribute | stretch)
// 2012 - align-content (flex-start | flex-end | center | space-between | space-around | stretch)
@mixin align-content($value: stretch) {

    $value-2011: $value;
    @if $value == "flex-start" {
        $value-2011: start;
    }    

    @elseif $value == "flex-end" {
        $value-2011: end;
    }

    @elseif $value == "space-between" {
        $value-2011: justify;
    }

    @elseif $value == "space-around" {
        $value-2011: distribute;
    }

    // 2012
    @include prefixer(align-content, $value, webkit moz spec);

    // 2011 (IE 10)
    -ms-flex-line-pack: $value-2011;
}


// -------------------------------------------------------------------
// Omega Reset for Bourbon Neat
//
// http://joshfry.me/notes/omega-reset-for-bourbon-neat
// -------------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter($grid-columns, $gutter); }
  &:nth-child(#{$nth}+1) { clear: none }
}



@mixin antialiasing( $type:antialiased ) {
  /* antialiased, none, subpixel-antialiased*/
    -webkit-font-smoothing: $type;
    font-smoothing: $type;
    font-smooth: always;
}