.band {
    position: relative;
    @include rem(padding, 20px 0);
    transition: padding $animation_timing $animation_easing;

    @include media($phablet) {
        @include rem(padding, 30px 0);
    }
    @include media($tablet) {
        @include rem(padding, 40px 0);
    }
    @include media($laptop) {
        @include rem(padding, 50px 0);
    }
    @include media($desktop) {
        @include rem(padding, 60px 0);
    }

    .band-title {
        margin: 0;
        @include rem(margin-bottom, 50px);
    }

    // Alternates
    &.pad-top {
        padding-bottom: 0;
    }

    // Alignment
    &[data-align="center"] {
        text-align: center;
    }

    // Themes
    &[data-theme="core-off-white"] {
        background-color: lighten($color_core_off_white, 5%);
    }

    &[data-theme="core-dark-green"] {
        background-color: $color_accent_dark_green;
        color: #ffffff;
    }

    // Hero
    &.hero {
        background-color: $color_accent_dark_green;
        color: #fff;
        text-align: center;

        @include media($tablet) {
            text-align: left;
        }

        .wrapper {
            @include media($tablet) {
                @include display(flex);
                @include flex-wrap(wrap);
                @include align-items(center);
                @include justify-content(center);

                @include media($laptop) {
                    @include flex-wrap(nowrap);
                }
            }
        }

        .band-subtitle {
            color: #ffffff;
        }

        .message {
            @include media($laptop) {
                width: 50%;
                @include rem(padding-left, 30px);
                @include rem(margin-bottom, 30px);
            }

            h1 {
                line-height: 1.1;

                @include media($laptop) {
                    @include rem(margin, 0 -32px 32px 0);
                }
            }
            h1 + p {
                @include rem(margin-bottom, 32px);
            }
            p + .button {
                @include rem(margin-bottom, 10px);

                @include media($laptop) {
                    margin: 0;
                }
            }
        }
        .wrapper > img {
            display: none;

            @include media($tablet) {
                display: block;
                width: 50%;
            }
        }

    }

    // Features
    &.features {
        .band-subtitle {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    // About
    &.about {
        background-color: #dddddd;
        @include media($laptop) {
            padding: 20px 0;
        }

        .wrapper {
            @include media($tablet) {
                display: flex;
                align-items: center;
            }
        }

        .about__image {
            @include media($tablet) {
                width: calc(40% - 50px);
                margin-right: 50px;
            }
        }

        .about__content {
            @include media($tablet) {
                width: 60%;
            }
        }

        .band-title {
            margin: 0 0 15px;
            font-weight: 400;
        }
    }

    // Pricing
    &.pricing {
        .band-subtitle {
            width: 66%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    // Page title
    &.page-title {
        padding: 0;

        h1 {
            display: inline-block;
            margin: 0;
            font-weight: 600;
        }
        h1:after {
            content: '';
            display: block;
            height: 2px;
            background-color: $color_accent_blue;
            @include rem(border-radius, 4px);
        }
        .button {
            float: right;
            @include rem(margin-left, 8px);
        }

    }
}