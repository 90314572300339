//==================================================================
// Progress Element Partial
// Styles for various multimedia elements.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  height: 1.8rem;
  min-width: 60%;
  border: 1px solid $color_core_black;

  background-color: $color_core_off_white;    /* BG for IE10+ & Firefox */
  color: $color_accent_blue;                  /* Bar for IE10+ */

  transition: width 2s $animation_easing;     /* Animation for IE10+ */

  &::-webkit-progress-value {
    background: $color_accent_blue;           /* Bar for Chrome */
    transition: width 2s $animation_easing;   /* Animation for Chrome */
  }
  &::-moz-progress-bar {
    background: $color_accent_blue;           /* Bar for Firefox */
    transition: width 2s $animation_easing;   /* Animation for Firefox */
  }
  &::-webkit-progress-bar {
    background: $color_core_off_white;        /* BG for Chrome */
  }

  &.success {
    color: $color_accent_lime_green;
    &::-webkit-progress-value { background: $color_accent_lime_green; }
    &::-moz-progress-bar { background: $color_accent_lime_green; }
  }

  &.error {
    color: $color_accent_red;
    &::-webkit-progress-value { background: $color_accent_red; }
    &::-moz-progress-bar { background: $color_accent_red; }
  }
}