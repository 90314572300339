//==================================================================
// Footer Partial
// Styles for footer layout.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Footer
// =================================================================
.footer-container {
  background-color: $color_accent_dark_green;
  color: #ffffff;
  text-align: center;
  padding: 0 0 80px;
  p, a {
    font-size: 14px;
  }
}

#footer-copyright {
  float: left;
  text-align: left;
  span {
    display: none;
    @include media($phablet) {
      display: inline;
    }
  }
}

#footer-links {
  float: right;
  text-align: right;

  a {
    color: #ffffff;
    text-decoration: none;
    transition: all $animation_timing $animation_easing;
    border-bottom: 1px solid transparent;
    &:hover,
    &:focus,
    &active {
      border-bottom: 1px solid #ffffff;
    }
  }
}

.disclosure {
  padding: 20px 0;
  h3 {
    margin: 0 0 15px;
  }
}