//==================================================================
// Buttons Partial
// Styles for various traditional actions.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================


input[type="submit"], button, a.button {
  display: inline-block;
  background-color: $color_accent_blue;
  border: 1px solid $color_accent_blue;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  @include rem(padding, 10px 20px);
  @include rem(border-radius, $border_radius);
  transition:
    background $animation_timing $animation_easing,
    border $animation_timing $animation_easing,
    color $animation_timing $animation_easing;

  img {
    display: inline-block;
    @include rem(width, 16px);
    @include rem(height, 16px);
    @include rem(margin-right, 10px);
  }

  // Alternatives
  &.accent {
    background-color: $color_accent_lime_green;
    border-color: $color_accent_lime_green;
  }
  &.naked {
    background-color: transparent;
    border-color: transparent;
    color: $color_accent_blue;
  }
  &.ghost {
    background-color: transparent;
    color: $color_accent_blue;
  }
  &.delete {
    padding: 0;
    text-transform: none;
    @include rem(margin-top, 32px);
  }
  &.ghost.inverted {
    border-color: #fff;
    color: #fff;
  }

  // Hover states
  &:hover {
    background-color: darken($color_accent_blue, 15%);
    border-color: darken($color_accent_blue, 15%);
  }
  &.accent:hover {
    background-color: darken($color_accent_lime_green, 15%);
    border-color: darken($color_accent_lime_green, 15%);
  }
  &.naked:hover {
    background-color: lighten($color_core_off_white, 5%);
    border-color: transparent;
  }
  &.ghost:hover {
    color: #fff;
  }
  &.delete:hover {
    color: $color_accent_red;
    background-color: transparent;
  }
  &.ghost.inverted:hover {
    background-color: #fff;
    color: $color_core_black;
  }
  &.delete-prompt-global {
    margin: 10px 0px 10px 0px;
  }
  &.select-all {
    width: 120px;
  }
}