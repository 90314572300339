// Map
.map {
  width: 100%;
  height: 100%;
  background-color: $color_accent_dark_green !important;
  z-index: 1;
  transition: filter 500ms;
  filter: brightness(1);

  &.pane-open {
    width: calc(100% - #{$pane_width});
    margin-left: $pane_width;
  }

  &.isGray {
    filter: brightness(0.5);
  }
}

// Loading Spinner (from https://tobiasahlin.com/spinkit/)
.sk-chase {
  width: 40px;
  height: 40px;
  position: absolute;
  inset: 50%;
  z-index: 5;
  animation: sk-chase 2.5s infinite linear both;
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
    
    &:before {
      content: ' ';
      display: block;
      width: 25%;
      height: 25%;
      background-color: $color_core_off_white;
      border-radius: 100%;
      animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
    }
    
    @for $i from 1 through 6 {
      &:nth-child(#{$i}),
      &:nth-child(#{$i}):before {
        animation-delay: -1.2s + ($i * 0.1s)
      }
    }
  }
}

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

// Details Pane
.map-pane {
  position: absolute;
  width: $pane_width;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);

  a, a:hover { text-decoration: none; }
  strong { text-transform: capitalize; }
  sup { top: initial; font-size: 100%; }

  ol, ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .pane-content {
    background-color: #fff;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:hover {
      overflow-y: auto;
    }
  }

  .pane-content-holder {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow-x: visible;
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: $pane_width;
  }

  .pane-listbox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .pane-header {
    background-color: $color_accent_blue;
    color: #fff;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    @include rem(font-size, 13px);

    .pane-header-icon {
      float: right;
      img {
        height: 48px;
      }
    }

    .pane-header-hero {
      overflow: hidden;
      direction: ltr;
      display: block;
      width: 100%;
      min-height: 100px;
      max-height: 450px;

      img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .pane-header-description {
      @include rem(padding, 16px 24px 20px);
      .pane-header-title {
        @include rem(font-size, 18px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }
      .pane-header-subtitle {
        @include rem(font-size, 13px);
        div { margin-top: 4px; }
      }
    }
  }

  .pane-actions {
    padding: 4px;
    position: relative;
    min-height: 80px;
    border-bottom: 1px solid $color_core_off_white;
    .pane-actions-buttons {
      display: flex;
      align-items: flex-start;
      padding: 4px 12px;
      min-height: 70px;
      .pane-action {
        background: none;
        border: none;
        @include rem(font-size, 13px);
        font-weight: 600;
        cursor: pointer;
        color: $color_accent_blue;
        text-transform: uppercase;
        position: relative;
        overflow: hidden;
        flex: 1;
        padding: 4px;
        text-align: center;
        &:hover {
          background: none;
          .pane-icon {
            filter: drop-shadow(0px 0px 3px rgba($color_accent_blue, 0.6));
          }
        }
        .pane-icon {
          display: block;
          margin: 0 auto 6px;
        }
      }
    }
  }

  .pane-icon {
    background-image: url(/img/i_marker-property.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;

    &.icon-back   { background-image: url(/img/i_back.png); }
    &.icon-taxdb  { background-image: url(/img/i_taxdb.png); }
    &.icon-zillow { background-image: url(/img/l_zillow.png); }
    &.icon-cc     { background-image: url(/img/i_cc.png); }
    &.icon-bpo    { background-image: url(/img/i_bpo.png); }
    &.icon-pi     { background-image: url(/img/i_pi.png); }
    &.icon-re     { background-image: url(/img/i_re.png); }
    &.icon-title  { background-image: url(/img/i_title.png); }
    &.icon-ma     { background-image: url(/img/i_ma.png); }
    &.icon-mr     { background-image: url(/img/i_mr.png); }
    &.icon-how-much     { background-image: url(/img/i_how-much.png); }
  }

  .pane-section {
    margin-top: 6px;
    @include rem(padding, 12px);
    width:100%;

    li {
      padding: 4px 0;
      line-height: 1.5em;
      strong {
        margin-right: 4px;
      }
    }

    ul {
      text-align: right;
      strong { float:left; }
      li {
        @include rem(font-size, 13px);
      }
    }
  }
  .pane-iframe {
    position: relative;
    display: block;
    clear: both;
    width: 100%;
    min-height: 800px;
    max-height: 100%;
    overflow: hidden;
    margin-top: 6px;

    iframe {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}

// Map Toolbar
.map-toolbar {
  position: absolute;
  left: 0;
  @include rem(top, 83px);
  background-color: $color_accent_dark_green;
  border: 0 2px 2px 0 solid lighten($color_accent_dark_green, 10%);
  box-shadow:  6px 6px rgba(#000, .2);
  color: #fff;
  z-index: 3;
  overflow: hidden;
  border-radius: $border_radius;
  width: $pane_width;

  .map-search, .map-filters {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
  .field {
    margin: 0;
  }

  // Map search
  .map-search {
    input {
      width: 100%;
      @include rem(padding, 6px);
      color: $color_core_black;
      border-radius: 0;
    }
  }

  // Map filters
  .map-filters {
    display: table;
    font-size: 0;
    line-height: 0;
    padding: 0;
    @include rem(height, 40px);

    .field {
      display: table-cell;
      padding: 2px 0 0;
      vertical-align: middle;
      text-align: center;
      @include rem(font-size, 14px);
      @include rem(line-height, 28px);
      white-space: nowrap;
      width: 25%;
      &:first-child {
        margin: 0;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 104%;
        height: 2px;
      }
      &:first-child:before {
        background-color: #ffbb00;
      }
      &[data-type="banks"]:before {
        background-color: $color_accent_lime_green;
      }
      &[data-type="properties"]:before {
        background-color: $color_accent_blue;
      }
      &:last-child:before {
        background-color: $color_accent_blue;
      }
      input, label {
        vertical-align: middle;
      }
    }
  }

  .additional-filters-button {
    font-size: 12px;
    padding: 5px;
    border-radius: 0;
    width: 100%;
    overflow: hidden;
  }

  .additional-filters {
    line-height: 0;
    padding: 0 0 10px 10px;
    display: none;

    .additional-filter {
      padding: 10px 0 10px 0;

      .filter-type {
        margin: 0 0 15px 0;
      }
      .field {
        @include rem(font-size, 14px);
        @include rem(line-height, 28px);

        input, select {
          color: #000000;
        }

        input, label {
          vertical-align: middle;
        }
      }
    }
  }

}


// Map Info
.map-info {
  font: 400 10px/1.5 $base_font;

  @include media($tablet) {
    font: 400 14px/1.5 $base_font;
  }

  h2 {
    width: 100%;
    @include rem(font-size, 21px);
  }
  h4 {
    margin: 0;
    text-transform: uppercase;
    color: $color_accent_dark_green;
    letter-spacing: 0;
    @include rem(font-size, 14px);
  }

  .location-info, .contact-info, .property-info {
    float: left;
    @include rem(margin-top, 16px);
  }
  .location-info {
    @include rem(margin-right, 16px);
    @include media($tablet) {
      @include rem(margin-right, 32px);
    }
  }

  .price-info {
    color: $color_accent_lime_green;
    @include rem(font-size, 14px);

    @include media($tablet) {
      @include display(flex);
      @include rem(font-size, 18px);
    }

    div {
      line-height: 1.25;
      @include rem(margin-right, 32px);
      @include rem(margin-bottom, 4px);
    }
    sup {
      font-size: .5em;
      top: 0;
      vertical-align: super;
    }
    span {
      display: block;
      font-weight: 600;
      color: darken($color_core_off_white, 25%);
      font-size: .75em;
    }
  }

  .buttons {
    clear:both;
    .button {
      float: left;
      background-position: center;
      background-repeat:no-repeat;
      background-size: 80%;
      @include rem(margin-top, 8px);
    }
    .button.ghost {
      float: right;
      @include rem(margin-left, 4px);
    }
    .button.btn_zillow {
      background-image: url('/img/l_zillow.png');
    }
    .button.btn_zillow:hover {
      background-color: lighten($color_accent_blue, 35%);
    }
    @include media($tablet) {
      float: left;
      width: 100%;
      clear: both;
      border-top: 1px solid $color_core_off_white;
      @include rem(margin-top, 16px);
      @include rem(padding-top, 16px);
    }
  }

  &[data-type="bank"] {}
  &[data-type="property"] {
    @include media($tablet) {
      position: relative;
      @include rem(padding-left, 148px);
    }

    .property-data-header {
      display: flex;
      align-items: center;
      img {
        height: 48px;
        padding-left: 10px;
      }
    }

    .property-photo {
      background: transparent url('/img/p_location-no-photo.png') no-repeat center top;
      background-size: contain;
      @include rem(width, 128px);
      @include rem(height, 128px);

      @include media($tablet) {
        position: absolute;
        top: 0; left: 0;
        @include rem(width, 128px);
        @include rem(height, 256px);
      }
    }
    h2 {
      margin-bottom: 0;
    }
    h2 + p {
      margin-top: 0;
      text-transform: uppercase;
      font-weight: 600;
      color: darken($color_core_off_white, 25%);
      @include rem(font-size, 14px);
    }
  }
}
