.account-block {
  margin: 0 auto;
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  @include rem(top, 50px);
  @include rem(padding, 50px 30px 60px);

  @include media($phablet) {
    position: static;
    @include rem(width, 480px);
    @include rem(padding, 50px);
    @include rem(border-radius, $border_radius);
  }

  // Tabs
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    @include rem(margin, -15px 0 40px);

    li {
      display: inline-block;
      @include rem(margin-right, 20px);
      @include rem(font-size, 24px);
    }
    li.active:after {
      content: '';
      display: block;
      height: 2px;
      background-color: $color_accent_blue;
      @include rem(border-radius, 4px);
    }
    li a {
      color: $color_core_off_white;
      text-decoration: none;
    }

    li a:hover {
      color: $color_core_black;
    }
  }


  // Inputs
  input {
    width: 100%;
  }
  input[type="checkbox"] {
    width: auto;
  }

  // Buttons
  .buttons:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color_core_off_white;
    @include rem(margin, 20px 0);
  }
  button[type="submit"] {
    width: 100%;
  }
  button[type="submit"] + a {
    display: block;
    text-align: center;
    @include rem(margin-top, 20px);
  }

  // Thank you
  &.thank-you {
    width: 100%;
    text-align: center;
    @include rem(padding, 40px 10px);

    .payment-form {
      @include rem(width, 240px);
      @include rem(margin, 20px auto);

      &:first-child, &:last-child {
        @include rem(width, 200px);
      }
    }

    @include media($phablet) {
      @include rem(width, 480px);
      @include rem(padding, 40px 20px);

      .payment-form {
        @include rem(width, 160px);
        float: left;
        margin: 0;

        // &:first-child, &:last-child {
        //   @include rem(margin-top, 10px);
        //   @include rem(width, 130px);
        // }
        &:first-child  { @include rem(margin-right, 10px); }
        &:last-child   { @include rem(margin-left,  10px); }
      }
    }

    @include media($tablet) {
      @include rem(width, 640px);
      @include rem(padding, 40px 30px);

      .payment-form {
        @include rem(width, 220px);

        &:first-child, &:last-child {
          @include rem(width, 160px);
          @include rem(margin-top, 10px);
        }
        &:first-child  { @include rem(margin-right, 20px); }
        &:last-child   { @include rem(margin-left,  20px); }
      }
    }

    @include media($laptop) {
      @include rem(width, 960px);
      @include rem(padding, 40px);

      .payment-form {
        @include rem(width, 320px);

        &:first-child, &:last-child {
          @include rem(width, 240px);
          @include rem(margin-top, 16px);
        }
        &:first-child  { @include rem(margin-right, 40px); }
        &:nth-child(2) h4 { @include rem(font-size, 26px); }
        &:last-child   { @include rem(margin-left,  40px); }
      }
    }

    > img {
      @include rem(width, 96px);
      @include rem(height, 96px);
      @include media($tablet) {
        @include rem(width, 128px);
        @include rem(height, 128px);
      }
    }
    h1 {
      @include rem(font-size, 36px);
      @include rem(margin, 12px 0 0);
    }
    h4 {
      @include rem(font-size, 20px);
      font-weight: bold;
      margin: 8px 0;
    }
    h5 {
      @include rem(font-size, 18px);
      font-weight: normal;
      margin: 4px 0;
    }
    p {
      color: darken($color_core_off_white, 25%);
      @include rem(margin, 24px auto 32px);
      @include rem(max-width, 480px);
    }
    input {
      border: none;
      @include rem(max-width, 260px);
    }
  }

  .payment-plans {
    @include media($phablet) {
      display: flex;
      justify-content: center;
    }
  }

}