//==================================================================
// Global Settings
// Central location for project-level variables.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================


// Breakpoint Settings
$phone   : new-breakpoint(min-width 320px 2);
$phablet : new-breakpoint(min-width 480px 4);
$tablet  : new-breakpoint(min-width 768px 6);
$laptop  : new-breakpoint(min-width 1024px 12);
$desktop : new-breakpoint(min-width 1280px 12);


// Pane Width
$pane_width: 500px;


// Animation
$animation_timing: 0.25s;
$animation_easing: ease-in-out;


// Border radius
$border_radius: 3px;


// Fonts
$base_font: "Open Sans", Helvetica, Arial, sans-serif; // 400, 600, 700


// Colors
$color_core_black: #454545;
$color_core_off_white: #E0E0E0;
$color_accent_dark_green: #18272f;
$color_accent_blue: #2196F3;
$color_accent_indigo: #3F51B5;
$color_accent_dark_purple: #311B92;
$color_accent_lime_green: #6ecc66;
$color_accent_red: #C62828;