.features-list {
    padding: 0;
    margin: 0;
    list-style: none;
    @include display(flex);
    @include flex-wrap(wrap);
    @include flex-direction(column);
    @include align-items(center);

    @include media($tablet) {
        @include flex-direction(row);
        @include align-items(flex-start);
        @include justify-content(space-between);
    }

    @include media($laptop) {
        @include rem(margin, 0 -30px);
    }

    li {
        @include rem(margin, 0 0 30px);

        @include media($tablet) {
            width: calc(50% - 60px);
            @include rem(margin, 0 30px 50px);
        }

        @include media($laptop) {
            width: calc(33.33% - 60px);
        }

        img {
            @include rem(width, 200px);
            @include rem(height, 200px);
        }

        h3 {
            margin: 0 0 15px;
        }

        .button {
            @include rem(padding, 6px 20px);
        }
    }
}