//==================================================================
// Typography Partial
// Styles for overall typography and misc. content components.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

html, body {
  font: 400 16px/1.5 $base_font;
  color: $color_core_black;
  @include antialiasing();
}

*:focus {
  outline: 0;
}


// Headings
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.25;
  @include rem(letter-spacing, -1px);
}
h1 {
  word-break: break-word;
  @include rem(font-size, 24px);
  @include rem(margin, 30px 0);

  @include media($tablet) {
    @include rem(font-size, 36px);
    @include rem(margin, 30px 0 60px);
  }
  @include media($desktop) {
    @include rem(font-size, 48px);
  }
}
h2 {
  @include rem(font-size, 21px);

  @include media($tablet) {
    @include rem(font-size, 24px);
  }
  @include media($desktop) {
    @include rem(font-size, 36px);
  }
}
h3 {
  @include rem(font-size, 18px);

  @include media($tablet) {
    @include rem(font-size, 21px);
  }
  @include media($desktop) {
    @include rem(font-size, 26px);
  }
}
h4 {
  @include rem(font-size, 18px);

  @include media($desktop) {
    @include rem(font-size, 21px);
  }
}
h5 {
  @include rem(font-size, 16px);

  @include media($desktop) {
    @include rem(font-size, 18px);
  }
}
h6 {
  @include rem(font-size, 16px);
}


// Paragraphs
p {}
strong {
  font-weight: 600;
}


// Links
a {
  color: $color_accent_blue;
  transition: color $animation_timing $animation_easing;

  &:hover {
    color: darken($color_accent_blue, 15%);
  }
}