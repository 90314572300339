//==================================================================
// Tables Partial
// Styles for tables and table componenets.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

table {
  width: 100%;

  th, td {
    border: 1px solid $color_core_off_white;
    text-align: center;
    @include rem(padding, 10px);
  }

  .button {
    @include rem(padding, 4px 10px);
    @include rem(font-size, 14px);
  }

  thead th {
    background-color: $color_core_black;
    border-color: lighten($color_core_black, 15%);
    color: #fff;
  }

  // Location type
  th.type {
    @include rem(width, 160px);
    @include rem(padding-left, 20px);
  }
  td.type {
    position: relative;
    text-transform: capitalize;
    text-align: left;
    @include rem(padding-left, 50px);
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      display: inline-block;
      background-color: $color_core_off_white;
      vertical-align: middle;
      @include rem(left, 20px);
      @include rem(width, 16px);
      @include rem(height, 16px);
      @include rem(margin-right, 10px);
      @include rem(border-radius, 16px);
      transform: translateY(-50%);
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }

    &[data-type="bank"]:before {
      background-color: $color_accent_lime_green;
    }
    &[data-type="property"]:before {
      background-color: $color_accent_blue;
    }

  }

  // Hover states
  tbody tr:hover th,
  tbody tr:hover td {
    background-color: lighten($color_accent_blue, 40%);
  }
}