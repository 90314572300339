@charset "UTF-8";

/// Generates vendor prefixes.
///
/// @argument {string} $property
///   Property to prefix.
///
/// @argument {string} $value
///   Value to use.
///
/// @argument {list} $prefixes
///   Vendor prefixes to output.
///
/// @example scss
///   .element {
///     @include prefixer(appearance, none, ("webkit", "moz"));
///   }
///
///   // CSS Output
///   .element {
///     -webkit-appearance: none;
///     -moz-appearance: none;
///     appearance: none;
///   }
///
/// @author Hugo Giraudel

@mixin prefixer(
    $property,
    $value,
    $prefixes: ()
  ) {

  @each $prefix in $prefixes {
    #{"-" + $prefix + "-" + $property}: $value;
  }
  #{$property}: $value;
}
