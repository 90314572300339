//==================================================================
// Navigations Partial
// Styles for various types of navigation componenets.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

nav {
  @include media($laptop) {
    float: right;  
  }

  h1 {
    float: right;
    margin: 0;
    background-color: rgba(#000, .25);
    border: 1px solid rgba(#fff, .1);
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    @include rem(font-size, 16px);
    @include rem(padding, 10px 20px);
    @include rem(border-radius, $border_radius);

    @include media($laptop) {
      display: none;
    }
  }
  ul {
    margin: 0;
    list-style: none;
    text-align: center;
    clear: both;
    @include rem(padding, 10px 0);

    @include media($laptop) {
      padding: 0;
      text-align: right;
    }

    li {
      @include media($laptop) {
        display: inline-block;  
      }      
      
      a.button {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 600;
        @include rem(font-size, 14px);  
      }
      a.button.naked:hover {
        background-color: lighten($color_accent_dark_green, 5%);
        color: #fff;
      }
    }
  }

}